import React from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter as Router, Route } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import { SnackbarProvider } from 'notistack'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import './index.css'
import theme from './theme'
import NotFoundPage from './pages/not-found/not-found'
import BookAppointment from './pages/book-appointment/book-appointment'
import AppointmentConfirmation from './pages/appointment-confirmation/appointment-confirmation'
import * as serviceWorker from './serviceWorker'
import SalonPage from './pages/salon-page/salon-page'

const Index = () => {
  return (
    <div>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        preventDuplicate
      >
        <Router>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <Route path="/error/not-found" component={NotFoundPage} />
              <Route exact path="/" component={NotFoundPage} />

              <Route exact path="/:salonId" component={SalonPage} />
              <Route
                exact
                path="/:salonId/book-appointment/"
                component={BookAppointment}
              />
              <Route
                exact
                path="/appointment-confirmed/:clientId/:appointmentId"
                component={AppointmentConfirmation}
              />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Router>
      </SnackbarProvider>
    </div>
  )
}

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React, { useState } from 'react'
import { Button, Stepper, Step, StepLabel, makeStyles } from '@material-ui/core'
import useMicrosite, {
  Service,
  Menu,
  MenuCategory,
  Staff,
  SalonHours,
} from '../../hooks/useMicrosite'
import AppBar from '../../components/app-bar'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import { Menu as MenuComponent, MenuItem } from '../../components/menu/menu'
import { SelectDateTime } from '../../components/select-date-time/select-date-time'
import { SelectStaff } from '../../components/select-staff/select-staff'
import { Moment } from 'moment'
import { Summary } from '../../components/summary/summary'
const R = require('ramda')

export type DisplayCategory = {
  category: string
  subcategories: {
    subcategory: string
    service: Service
  }[]
}

const transformCategories = (values: MenuCategory[], category: string) => ({
  category,
  subcategories: R.compose(
    R.values,
    R.mapObjIndexed((v: Service, subcategory: string) => ({
      subcategory,
      service: v,
    }))
  )(values),
})

const transformMenu = (menu: Menu | undefined): DisplayCategory[] =>
  R.compose(R.values, R.mapObjIndexed(transformCategories))(menu)

const steps = [
  'Select Your Service',
  'Select Staff',
  'Select Date & Time',
  'Summary & Purchase',
]

const getContent = ({
  currentStep,
  salonId,

  menu,
  handleMenuItemSelected,
  selectedMenuItem,

  staff,
  handleStaffMemberSelected,
  selectedStaffMember,

  hours,
  selectedDateTime,
  handleDateTimeSelected,

  handleUserIdSet,
}: {
  currentStep: number
  salonId: string

  menu: DisplayCategory[]
  selectedMenuItem?: MenuItem
  handleMenuItemSelected: (menuItem: MenuItem) => void

  staff: Staff[]
  selectedStaffMember?: Staff
  handleStaffMemberSelected: (staffMember: Staff) => void

  hours?: SalonHours
  selectedDateTime?: Moment
  handleDateTimeSelected: (dateTime: Moment) => void

  handleUserIdSet: (userId: string) => void
}) => {
  switch (currentStep) {
    case 0:
      return (
        <MenuComponent
          onMenuItemSelected={handleMenuItemSelected}
          value={selectedMenuItem}
          menu={menu}
        />
      )
    case 1:
      return (
        <SelectStaff
          value={selectedStaffMember}
          onStaffSelected={handleStaffMemberSelected}
          staff={staff}
        />
      )
    case 2:
      return (
        <SelectDateTime
          hours={hours}
          value={selectedDateTime}
          onDateTimeSelected={handleDateTimeSelected}
        />
      )
    case 3:
      return (
        <Summary
          salonId={salonId}
          dateTime={selectedDateTime}
          staff={selectedStaffMember}
          menuItem={selectedMenuItem}
          onUserId={handleUserIdSet}
        />
      )
    case 4:
      return 'Done'
    default:
      return 'Unknown stepIndex'
  }
}

const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    margin: '0 1em',
  },
})

const resolveContinueDisabled = (
  step: number,
  data: {
    menuItem?: MenuItem
    staffMember?: Staff
    userId?: string
    dateTime?: Moment
  }
) => {
  const { menuItem, staffMember, userId, dateTime } = data

  if (step === 0) {
    return !menuItem
  }

  if (step === 1) {
    return !staffMember
  }

  if (step === 2) {
    return !dateTime
  }

  if (step === 3) {
    console.log({ userId })
    return !userId
  }
}

export const BookAppointment = (props: RouteComponentProps) => {
  const css = useStyles()
  const params: { salonId?: string } = props.match.params

  const { microsite, loading, staff } = useMicrosite(params.salonId || '')

  const [activeStep, setActiveStep] = React.useState(0)
  const [menuItem, setMenuItem] = useState<MenuItem | undefined>(undefined)
  const [staffMember, setStaffMember] = useState<Staff | undefined>(undefined)
  const [dateTime, setDateTime] = useState<Moment | undefined>(undefined)
  const [userId, setUserId] = useState<string | undefined>(undefined)

  if (loading) {
    return <div>Loading...</div>
  }

  if (!microsite) {
    return <Redirect to="/error/not-found" />
  }
  const menu = transformMenu(microsite.menu)

  return (
    <>
      <AppBar name={microsite.businessName} home={microsite.slug} />

      <Stepper activeStep={activeStep}>
        {steps.map((step) => (
          <Step key={step}>
            <StepLabel>{step}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {getContent({
        currentStep: activeStep,
        salonId: microsite.salonId,

        menu,
        selectedMenuItem: menuItem,
        handleMenuItemSelected: setMenuItem,

        staff,
        selectedStaffMember: staffMember,
        handleStaffMemberSelected: setStaffMember,

        hours: microsite.hours,
        selectedDateTime: dateTime,
        handleDateTimeSelected: setDateTime,

        handleUserIdSet: setUserId,
      })}

      <div className={css.buttons}>
        {activeStep !== 0 && (
          <Button
            color="primary"
            variant="contained"
            className={css.button}
            onClick={() => setActiveStep(activeStep - 1)}
          >
            Back
          </Button>
        )}

        {activeStep !== 4 && (
          <Button
            disabled={resolveContinueDisabled(activeStep, {
              menuItem,
              staffMember,
              userId,
              dateTime,
            })}
            onClick={() => setActiveStep(activeStep + 1)}
            color="primary"
            variant="contained"
            className={css.button}
          >
            Continue
          </Button>
        )}
      </div>
    </>
  )
}

export default BookAppointment

import React from 'react'
import { Address } from '../../hooks/useMicrosite'
import { Typography } from '@material-ui/core'

export default (props: { address: Address | undefined; className: string }) => {
  const { address, className } = props

  if (!address) {
    return null
  }

  return (
    <div className={className}>
      <Typography variant="h5">Address</Typography>
      {address.street}
      <br />
      {address.county}
      <br />
      {address.town}
      <br />
      {address.postCode}
      <br />
      {/* <Box
        style={{
          backgroundColor: 'lightgrey',
          height: '150px',
          width: '150px',
          margin: '1em',
        }}
      >
        Map
      </Box> */}
    </div>
  )
}

import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { DisplayCategory } from '../../pages/book-appointment/book-appointment'
import { Radio, RadioGroup } from '@material-ui/core'
import { Service } from '../../hooks/useMicrosite'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}))(TableCell)

const useStyles = makeStyles((theme) => ({
  table: {
    marginBottom: '2em',
    [theme.breakpoints.up('md')]: {
      maxWidth: '80vw',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '70vw',
    },
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1em',
    padding: '1em',
    alignItems: 'center',
  },
  categoryCell: {
    width: '45%',
  },
}))

export type MenuItem = {
  category: string
  subcategory: string
  service: Service
}

export function Menu({
  menu,
  onMenuItemSelected,
  value,
}: {
  menu: DisplayCategory[]
  onMenuItemSelected?: (item: MenuItem) => void
  value?: MenuItem
}) {
  const css = useStyles()

  return (
    <RadioGroup
      className={css.tableContainer}
      value={JSON.stringify(value) || ''}
      onChange={(e, val) => {
        onMenuItemSelected && onMenuItemSelected(JSON.parse(val))
      }}
    >
      {menu.map(({ category, subcategories }, index) => (
        <TableContainer
          key={`category-table-${category}`}
          className={css.table}
          style={{
            marginBottom: index === menu.length - 1 ? '0' : undefined,
          }}
          component={Paper}
        >
          <Table>
            <TableHead>
              <TableRow color="primary">
                <StyledTableCell className={css.categoryCell}>
                  {category}
                </StyledTableCell>
                <StyledTableCell align="right">Time</StyledTableCell>
                <StyledTableCell align="right">Price</StyledTableCell>
                <StyledTableCell align="right"></StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subcategories.map(({ subcategory, service }) => (
                <TableRow key={subcategory}>
                  <TableCell
                    className={css.categoryCell}
                    component="th"
                    scope="row"
                  >
                    {subcategory}
                  </TableCell>
                  <TableCell align="right">{service.clientTime}</TableCell>
                  <TableCell align="right">{service.price}</TableCell>
                  <TableCell align="right">
                    <Radio
                      color="primary"
                      value={JSON.stringify({
                        category,
                        subcategory,
                        service,
                      })}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ))}
    </RadioGroup>
  )
}

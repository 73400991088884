import React from 'react'
import { SalonHours, Hours } from '../../hooks/useMicrosite'
import { Typography } from '@material-ui/core'

const week: Array<keyof SalonHours> = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
]

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)

const getKeyValue = <U extends keyof T, T extends object>(key: U) => (obj: T) =>
  obj[key]

const DayHours = (props: { day: string; hours: Hours | undefined }) => (
  <div style={{ display: 'flex' }}>
    <Typography display="inline" align="left">
      {capitalize(props.day)}
    </Typography>
    <Typography
      style={{ flex: 1 }}
      display="inline"
      align="right"
    >{`${props.hours?.open} - ${props.hours?.close}`}</Typography>
  </div>
)

export default (props: { hours: SalonHours | undefined }) => {
  const { hours } = props

  if (!hours) {
    return null
  }

  return (
    <>
      <Typography variant="h5" >Salon Opening Times</Typography>

      <div style={{ margin: '1em' }}>
        {week.map((day) => (
          <DayHours
            key={`hours-for-${day}`}
            day={day}
            hours={getKeyValue<keyof SalonHours, SalonHours>(day)(hours)}
          />
        ))}
      </div>
    </>
  )
}

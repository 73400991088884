import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  Paper,
  Typography,
  Button,
  TextField,
} from '@material-ui/core'
import axios from 'axios'
import { Moment } from 'moment'
import { useSnackbar } from 'notistack'

import { MenuItem } from '../menu/menu'
import { Staff } from '../../hooks/useMicrosite'
import { useLoginUI } from '../../hooks/useLoginUI'
import { Redirect } from 'react-router-dom'

const { REACT_APP_API_BASE_URL } = process.env

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '1em',
    padding: '1em',
  },
  container: {
    maxWidth: '360px',
  },
  paper: {
    marginBottom: '1em',
    padding: '1em',
    flexDirection: 'column',
    display: 'flex',
  },
  input: {
    marginBottom: '1em',
  },
}))

export function Summary({
  salonId,
  menuItem,
  dateTime,
  staff,
  onUserId,
}: {
  salonId: string
  menuItem?: MenuItem
  dateTime?: Moment
  staff?: Staff

  onUserId: (userId: string) => void
}) {
  const css = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [userId, setUserId] = useState<string | null>(null)
  const [firstName, setFirstName] = useState<string | null>(null)
  const [lastName, setLastName] = useState<string | null>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [finished, setFinished] = useState<boolean>(false)
  const [redirect, setRedirect] = useState<string | null>(null)

  useEffect(() => {
    if (userId) {
      onUserId(userId)
    }
  }, [userId, onUserId])

  useLoginUI('#firebaseui-auth-container', setUserId)

  if (redirect) {
    return <Redirect to={redirect} />
  }

  return (
    <div className={css.wrapper}>
      <div className={css.container}>
        <Paper className={css.paper}>
          <Typography className={css.input}>
            {`${menuItem?.subcategory} with ${
              staff?.displayName
            } on ${dateTime?.format('dddd, MMM Do [at] hh:mm A')}`}
          </Typography>

          <TextField
            required
            className={css.input}
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            required
            className={css.input}
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />

          {userId && firstName && lastName && !finished && (
            <Button
              color="primary"
              variant="contained"
              size="large"
              disabled={submitting}
              onClick={async () => {
                setSubmitting(true)

                const payload = {
                  salonId,
                  staffId: staff?.key,
                  clientId: userId,
                  dateTime: dateTime?.toLocaleString(),
                  menuItem,
                  firstName,
                  lastName,
                }

                if (REACT_APP_API_BASE_URL) {
                  const result = await axios.post(
                    REACT_APP_API_BASE_URL,
                    payload
                  )

                  console.log(result.data)

                  if (result.status === 200) {
                    enqueueSnackbar('Booked successfully!', {
                      variant: 'success',
                    })

                    const { appointmentHistoryKey } = result.data as {
                      appointmentHistoryKey: string
                    }

                    setRedirect(
                      `/appointment-confirmed/${userId}/${appointmentHistoryKey}`
                    )

                    setFinished(true)
                  }
                }

                setSubmitting(false)
              }}
            >
              Book Now
            </Button>
          )}
        </Paper>
      </div>
      <div id="firebaseui-auth-container"></div>
    </div>
  )
}

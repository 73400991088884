import React from 'react'
import { AppBar, Toolbar, Typography } from '@material-ui/core'

import theme from '../../theme'
import { Link } from 'react-router-dom'

function NotFoundPage() {
  return (
    <>
      <AppBar position="static" style={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <Link style={{ color: 'inherit', textDecoration: 'none' }} to="/">
            <Typography variant="h6" style={{ flex: 1 }}>
              The Beauty App
            </Typography>
          </Link>
        </Toolbar>
      </AppBar>

      <Typography variant="h6" style={{ flex: 1 }}>
        Page Not Found
      </Typography>
    </>
  )
}

export default NotFoundPage

import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    primary: {
      // light: '#FFFFFF',
      // light: will be calculated from palette.primary.main,
      main: '#1b1b1b',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      // main: '#3174ad'
      main: '#FFFFFF',
      //   // dark: will be calculated from palette.secondary.main,
      //   contrastText: '#ffcc00'
    },
    // error: will use the default color
  },
})

import { useObjectVal } from 'react-firebase-hooks/database'

import { firebaseDb } from '../firebase'

type ClientAppointment = {
  customerId: string
  date: string // 'Fri Aug 28 2020 14:00:00 GMT-0600'
  duration: number
  menuItem: {
    category: string
    service: {
      clientTime: string
      price: string
      staffTime: string
    }
    subcategory: string
  }
  price: number
  salonId: string
  salonNameSnapshot: string
  staffId: string
  staffNameSnapshot: string
}

export const useAppointment = (clientId: string, appointmentId: string) => {
  const [appointment, loading, error] = useObjectVal<ClientAppointment>(
    firebaseDb.ref(
      `user_profile_mobapp_customers/${clientId}/appointments/${appointmentId}`
    )
  )

  return { appointment, loading, error }
}

export default useAppointment

import React, { useState, useEffect } from 'react'
import {
  makeStyles,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import moment, { Moment } from 'moment'

import { SalonHours } from '../../hooks/useMicrosite'

type MomentHours = {
  open: Moment
  close: Moment
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1em',
    margin: '1em',
    alignItems: 'center',
  },
  time: {
    minWidth: '10em',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  label: {
    marginBottom: '1em',
  },
}))

const getTime = (time: string): Moment => {
  const [hours, minutes] = time.split(':')
  return moment()
    .hours(parseInt(hours))
    .minutes(parseInt(minutes))
    .seconds(0)
    .milliseconds(0)
}

const getTimeString = (time: Moment) => time.format('HH:mm')

const hourMenuItem = (time: Moment) => {
  const value = getTimeString(time)
  return (
    <MenuItem key={`menu-item-${value}`} value={value}>
      {time.format('hh:mm A')}
    </MenuItem>
  )
}

const hourMenuItems = (open: Moment, close: Moment) => {
  const counter = open.clone()
  const items = []

  do {
    items.push(hourMenuItem(counter.clone()))
    counter.add('minutes', 30)
  } while (counter < close)

  return items
}

export function SelectDateTime({
  value,
  hours,
  onDateTimeSelected,
}: {
  hours?: SalonHours
  value?: moment.Moment
  onDateTimeSelected?: (dateTime: moment.Moment) => void
}) {
  const css = useStyles()

  const [dateTime, setDateTime] = useState<moment.Moment | null>(value || null)

  const [date, setDate] = useState<moment.Moment | null>(value || moment())
  const [time, setTime] = useState<string | null>(
    value ? getTimeString(value) : null
  )
  const [currentHours, setCurrentHours] = useState<MomentHours | null>(null)

  useEffect(() => {
    if (date && hours) {
      const weekday = date.format('dddd').toLowerCase() as keyof SalonHours
      const hoursStr = hours[weekday]

      if (hoursStr) {
        const open = getTime(hoursStr.open || '9:00')
        const close = getTime(hoursStr.close || '17:00')

        if (time) {
          const currentSelection = getTime(time)

          if (currentSelection < open) {
            setTime(getTimeString(open))
          }

          if (currentSelection > close) {
            setTime(getTimeString(close.clone().add('minutes', -30)))
          }
        }

        setCurrentHours({
          open,
          close,
        })
      }
    }
  }, [date, hours, time])

  useEffect(() => {
    if (time) {
      const m = getTime(time)
      const dt = moment(date)
        .hours(m.hours())
        .minutes(m.minutes())
        .seconds(0)
        .milliseconds(0)

      onDateTimeSelected && onDateTimeSelected(dt)
      setDateTime(dt)
    }
  }, [date, time, hours, onDateTimeSelected])

  return (
    <div className={css.wrapper}>
      <div className={css.container}>
        <DatePicker
          // disableToolbar
          disablePast
          autoOk
          orientation="landscape"
          variant="static"
          openTo="date"
          format="MM/DD/yyyy"
          label="Appointment Date"
          value={date}
          onChange={(val) => {
            setDate(val)
          }}
        />

        <FormControl className={css.time}>
          <InputLabel>Appointment Time</InputLabel>
          <Select
            value={time || ''}
            onChange={(e) => {
              setTime(e.target.value as string)
            }}
          >
            {currentHours &&
              hourMenuItems(currentHours.open, currentHours.close)}
          </Select>
        </FormControl>
      </div>

      <Typography className={css.label}>{`${
        dateTime?.format('dddd, MMM Do [at] hh:mm A') ||
        'Please select a date and time for your appointment.'
      }`}</Typography>
    </div>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'
import { AppBar, Toolbar, Typography } from '@material-ui/core'

import theme from '../theme'

export default ({ name, home }: { name?: string; home?: string }) => (
  <AppBar position="static" style={{ zIndex: theme.zIndex.drawer + 1 }}>
    <Toolbar>
      <Link
        style={{ color: 'inherit', textDecoration: 'none' }}
        to={home ? `/${home}` : '/'}
      >
        <Typography variant="h6" style={{ flex: 1 }}>
          {name || 'The Beauty App'}
        </Typography>
      </Link>
    </Toolbar>
  </AppBar>
)

import { useEffect } from 'react'
import * as firebaseui from 'firebaseui'

import { firebaseAuth } from '../firebase'

const uiConfig: firebaseui.auth.Config = {
  signInOptions: [
    {
      provider: firebaseAuth.PhoneAuthProvider.PROVIDER_ID,
      recaptchaParameters: {
        type: 'image', // 'audio'
        size: 'normal', // 'invisible' or 'compact'
        badge: 'bottomleft', //' bottomright' or 'inline' applies to invisible.
      },
      defaultCountry: 'GB',
      // You can provide a 'whitelistedCountries' or 'blacklistedCountries' for
      // countries to select. It takes an array of either ISO (alpha-2) or
      // E164 (prefix with '+') formatted country codes. If 'defaultCountry' is
      // not whitelisted or is blacklisted, the default country will be set to the
      // first country available (alphabetical order). Notice that
      // 'whitelistedCountries' and 'blacklistedCountries' cannot be specified
      // at the same time.
      // whitelistedCountries: ['US', '+44'],
    },
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: '<your-tos-url>',
  // Privacy policy url/callback.
  privacyPolicyUrl: function () {
    window.location.assign('<your-privacy-policy-url>')
  },
}

let authUI: firebaseui.auth.AuthUI

export const useLoginUI = (
  id: string,
  onLoginSuccess: (userId: string) => void
) => {
  useEffect(() => {
    if (!authUI) {
      authUI = new firebaseui.auth.AuthUI(firebaseAuth())
    }

    authUI.start(id, {
      ...uiConfig,
      callbacks: {
        signInSuccessWithAuthResult: (
          authResult: firebase.auth.UserCredential
        ) => {
          onLoginSuccess &&
            authResult.user?.uid &&
            onLoginSuccess(authResult.user?.uid)

          return false
        },
      },
    })
  }, [id, onLoginSuccess])
}

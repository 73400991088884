import React from 'react'
import { Staff } from '../../hooks/useMicrosite'
import {
  RadioGroup,
  Radio,
  makeStyles,
  TableContainer,
  TableCell,
  Paper,
  Table,
  TableBody,
  TableRow,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  table: {
    maxWidth: '360px',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '1em',
    padding: '1em',
    alignItems: 'center',
  },
}))

export function SelectStaff({
  staff,
  onStaffSelected,
  value,
}: {
  staff: Staff[]
  onStaffSelected?: (staff: Staff) => void
  value?: Staff
}) {
  const css = useStyles()

  return (
    <RadioGroup
      className={css.tableContainer}
      value={JSON.stringify(value || {})}
      onChange={(e, val) => {
        onStaffSelected && onStaffSelected(JSON.parse(val))
      }}
    >
      <TableContainer className={css.table} component={Paper}>
        <Table>
          <TableBody>
            {staff.map((staffMember) => (
              // <div className={css.container} key={`staff-${staffMember.key}`}>
              //   <Typography>{staffMember.firstName}</Typography>
              // </div>
              <TableRow key={staffMember.key}>
                <TableCell
                  // className={css.categoryCell}
                  component="th"
                  scope="row"
                >
                  {staffMember.displayName}
                </TableCell>
                <TableCell align="right">
                  <Radio color="primary" value={JSON.stringify(staffMember)} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </RadioGroup>
  )
}

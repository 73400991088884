import { useState, useEffect } from 'react'
import { useObjectVal } from 'react-firebase-hooks/database'

import { firebaseDb } from '../firebase'
import { getData } from '../utils'

const R = require('ramda')

export enum Currencies {
  USDollar = 'USD',
  BritishPound = 'GBP',
  AustralianDollar = 'AUD',
}

export type Hours = {
  open?: string
  close?: string
}

export type Address = {
  county?: string
  street?: string
  town?: string
  postCode?: string
}

export type SalonHours = {
  sunday?: Hours
  monday?: Hours
  tuesday?: Hours
  wednesday?: Hours
  thursday?: Hours
  friday?: Hours
  saturday?: Hours
}

export type MicroSite = {
  slug?: string
  blurb?: string
  currency?: Currencies
  logo?: string
  hours?: SalonHours
  paused?: boolean

  salonId: string
  businessName: string
  email?: string
  mobile?: string
  landline?: string

  address?: Address

  menu?: Menu
}

export type Menu = {
  [category: string]: MenuCategory
}

export type MenuCategory = {
  [subcategory: string]: Service
}

export type Service = {
  clientTime?: number
  staffTime?: number
  enabled?: boolean
  price?: number
}

export type Staff = {
  email?: string
  firstName?: string
  lastName?: string
  phone?: string
  displayName?: string
  key?: string
}

export const useMicrosite = (slug: string) => {
  const [staff, setStaff] = useState<Staff[]>([])
  const [microsite, loading] = useObjectVal<MicroSite>(
    firebaseDb.ref(`microsites/${slug}`)
  )

  // const [staff, staffLoading] = useListVals<any>(firebaseDb.ref(staffRef))

  useEffect(() => {
    const getStaffData = async () => {
      if (!microsite?.salonId) return

      const staffData = await getData<Staff[]>(
        firebaseDb.ref(`staff/${microsite.salonId}`)
      )

      const parsedStaff: Staff[] = R.compose(
        R.values,
        R.reject(R.propOr(false, 'isDeleted')),
        R.mapObjIndexed(
          ({ firstName, lastName, ...rest }: Staff, key: string) => {
            return {
              ...rest,
              firstName,
              lastName,
              displayName: `${firstName} ${lastName}`,
              key,
            }
          }
        )
      )(staffData)

      setStaff(parsedStaff)
    }

    getStaffData()
  }, [microsite])

  return { microsite, loading, staff }
}

export default useMicrosite

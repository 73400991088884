import React from 'react'
import { makeStyles, Paper, Typography, Button } from '@material-ui/core'
import { Service } from '../../hooks/useMicrosite'
import AppBar from '../../components/app-bar'
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import useAppointment from '../../hooks/useAppointment'

export type DisplayCategory = {
  category: string
  subcategories: {
    subcategory: string
    service: Service
  }[]
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1em',
    margin: '3em auto',
    maxWidth: '40vw',
  },
  title: {
    marginBottom: '1em',
  },
  button: {
    marginTop: '1em',
  },
})

export const AppointmentConfirmation = (props: RouteComponentProps) => {
  const css = useStyles()
  const params = props.match.params as {
    clientId: string
    appointmentId: string
  }

  const { clientId, appointmentId } = params

  const { appointment, loading } = useAppointment(clientId, appointmentId)

  if (loading) {
    return <div>Loading...</div>
  }

  const date = moment(appointment?.date)

  return (
    <>
      <AppBar name={appointment?.salonNameSnapshot} />

      <Paper elevation={4} className={css.container}>
        <Typography variant="h5">Appointment Confirmed!</Typography>

        <Typography className={css.title} variant="caption">
          We've got you confirmed for your appointment
        </Typography>

        <Typography variant="subtitle2">{`${
          appointment?.menuItem.subcategory
        } @ ${date.format('h:mm A')}`}</Typography>

        <Typography variant="caption">{`with ${
          appointment?.staffNameSnapshot
        } on ${date.format('dddd, MMMM Do')}.`}</Typography>

        <Button
          variant="contained"
          color="primary"
          className={css.button}
          href={`https://myportal${
            process.env.NODE_ENV === 'production' ? '' : '-stage'
          }.thebeautyapp.com/appointments`}
        >
          My Appointments
        </Button>
      </Paper>
    </>
  )
}

export default AppointmentConfirmation

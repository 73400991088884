import React from 'react'
import { RouteComponentProps, Redirect } from 'react-router-dom'
import { Button } from '@material-ui/core'

import useMicrosite from '../../hooks/useMicrosite'
import Address from '../../components/address/address'
import './salon-page.css'
import Hours from '../../components/hours/hours'
import AppBar from '../../components/app-bar'

function App(props: RouteComponentProps) {
  const { history } = props

  const params: { salonId?: string } = props.match.params
  const { microsite, loading } = useMicrosite(params.salonId ?? '')

  if (loading) {
    return <div>Loading...</div>
  }

  if (!microsite) {
    return <Redirect to="/error/not-found" />
  }

  return (
    <>
      <AppBar name={microsite.businessName} home={microsite.slug} />

      <div className="salon-page-wrapper">
        <div className="salon-page-logo">
          <img src={microsite?.logo} alt="logo" />
          <p>{microsite?.blurb}</p>
        </div>

        <div className="book-button">
          <Button
            disabled={microsite?.paused || false}
            size="large"
            variant="contained"
            color="primary"
            onClick={() => history.push(`/${microsite.slug}/book-appointment`)}
          >
            Book Now
          </Button>
        </div>

        {/* <Box className="salon-bio">Salon Bio Video...</Box> */}

        <Address className="address" address={microsite?.address} />

        <div className="hours">
          <Hours hours={microsite?.hours} />
        </div>
      </div>
    </>
  )
}

export default App
